import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useOpenChatModal, useShowChatOnPayment } from "../context";

export const ErrorCallbackPage = () => {

    const history = useHistory();
    const openChatModal = useOpenChatModal();
    const showChatOnPayment = useShowChatOnPayment();
    useEffect(() => {
        if(showChatOnPayment){
            openChatModal('payment');
        }

        history.push('/')
    })

    return (
        <>
        </>
    )
}