import { useCallback, useEffect, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { makeRequest } from "../utils/request";

import { useLocalStorage } from "@uidotdev/usehooks";

export const AppContext = createContext({
    errorText: '',
    isErrorShown: false,
    photoSrc: '',
})

AppContext.displayName = 'AppContext'

const CHAT_MODES = ['upload', 'express', 'payment'];

export const AppContextProvider = ({ children }) => {
    const [errorText, setErrorText] = useState('');
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [photoSrc, setPhotoSrc] = useState('')
    const [paywallMethod, setPaywallMethod] = useState('none');

    const [selfieMode, setSelfieMode] = useState(''); // can be 'one' or 'two'
    const [timeoutDelay, setTimeoutDelay] = useState(45);
    const [allowedNotifications, setAllowedNotifications] = useState(false);
    const [allowedPhotoShow, setAllowPhotoShow] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [blockSettings, setBlockSettings] = useState({
        showBlock: false,
        title: '',
        caption: '',
    });
    const [showExpressScore, setShowExpressScore] = useState(false);
    const [showSharing, setShowSharing] = useState(false);

    const [showPotential, setShowPotential] = useState(false);

    const [showBlockReport, setShowBlockReport] = useState(false);
    const [showButtonTips, setShowButtonTips] = useState(false);
    const [showButtonTipsUpload, setShowButtonTipsUpload] = useState(false);

    const [showNewDesign, setShowNewDesign] = useState(false);
    const [showExamples, setShowExamples] = useState(false);
    const [headerImgsShow, setHeaderImgsShow] = useState(true);

    const [fullAnalysis, setFullAnalysis] = useState([]);
    const [fullAnalysisButton, setFullAnalysisButton] = useState({});

    const [chatModalOpened, setChatModalOpened] = useState(false);
    const [chatMode, setChatMode] = useState('upload') //upload | express | payment

    const [step2Data, setStep2Data] = useState({});

    const closeChatModal = useCallback(() => {
        setChatModalOpened(false);

    }, [setChatModalOpened])

    const openChatModal = useCallback((mode) => {
        setChatModalOpened(true);
        if (CHAT_MODES.includes(mode)) {
            setChatMode(mode)
        } else {
            setChatMode('upload')
        }
    }, [setChatModalOpened])


    const [chatSettings, setChatSettings] = useLocalStorage('faceq-chat-settings', {
        upload: false,
        express: false,
        payment: false,
    },)

    const [historyBlockSettings, setHistoryBlockSettings] = useState({
        photoCount: 0,
        photoSrc,
        caption: '',
        photoId: '',
    })

    const [reportExamples, setReportExamples] = useState([]);

    useEffect(() => {
        const url = new URL(window.location.href);
        const utm_medium = url.searchParams.get("utm_medium") || undefined;
        const utm_source = url.searchParams.get("utm_source") || undefined;
        const utm_campaign = url.searchParams.get("utm_campaign") || undefined;
        const utm_term = url.searchParams.get("utm_term") || undefined;
        const utm_content = url.searchParams.get("utm_content") || undefined;
        const locale = navigator.language || undefined;
        makeRequest('/prepare', 'post', {
            utm_campaign,
            utm_medium,
            utm_content,
            utm_source,
            utm_term,
            locale
        }).then((response) => {
            if (response.data.status) {
                setTimeoutDelay(response.data.result.image_generation_time_limit);

                if (response.data.result.upload.selfie_cnt) {
                    setSelfieMode(response.data.result.upload.selfie_cnt === 1 ? 'one' : 'two');
                }

                if (response.data.result.is_express === 1) {
                    setShowExpressScore(true);
                }

                if (response.data.result.is_sharing_view === 1) {
                    setShowSharing(true);
                }

                if (response.data.result.block_cnt) {
                    setBlockSettings({
                        showBlock: response.data.result.block_cnt.view === 1,
                        title: response.data.result.block_cnt.title,
                        caption: response.data.result.block_cnt.caption,
                    })
                }

                if (response.data.result?.history?.cnt) {
                    setHistoryBlockSettings({
                        photoCount: response.data.result.history.cnt,
                        caption: response.data.result.history.caption,
                        photoSrc: response.data.result.history.photo_url,
                        photoId: response.data.result.history.photo_id,
                    })
                }

                if (response.data.result.is_report) {
                    setShowBlockReport(true);
                }

                if (response.data.result.is_button_beauty_tips) {
                    setShowButtonTips(true);
                }

                if (response.data.result.is_potential_view === 1) {
                    setShowPotential(true);
                }

                if (response.data.result.is_new_design === 1) {
                    setShowNewDesign(true);
                }

                if (response.data.result.examples) {
                    setReportExamples(response.data.result.examples)
                }

                if (response.data.result.is_example === 1) {
                    setShowExamples(true);
                }

                if (response.data.result.full_analysis) {
                    setFullAnalysis(response.data.result.full_analysis)
                }

                if (response.data.result.full_analysis_button) {
                    setFullAnalysisButton(response.data.result.full_analysis_button)
                }

                if (response.data.result.is_header_imgs_show === 0) {
                    setHeaderImgsShow(false)
                }

                if (response.data.result.is_button_example_tips === 1) {
                    setShowButtonTipsUpload(true)
                }

                if (response.data.result.step2) {
                    const step2 = response.data.result.step2;
                    const res = {
                        score: {
                            text: {
                                base: step2.score.status[0],
                                result: step2.score.status[1],
                            },
                            score: step2.score.overall.score,
                            firstBlock: step2.score.overall,
                            secondBlock: step2.score.potential,
                        },
                        full: {
                            firstBlock: step2.analysis.scoring[0],
                            secondBlock: step2.analysis.scoring[1],
                            textEvals: step2.analysis.typification,
                        },
                        top: step2.top,
                        insights: step2.insights,
                        step: step2.title,
                        scoreTitle: step2.score.title,
                        analysisTitle: step2.analysis.title,
                        insightsTitle: step2.insights.title,
                    }
                    setStep2Data(res);
                }

                if (response.data.result.chat) {
                    const chat = response.data.result.chat;
                    setChatSettings({
                        upload: chat.upload === 1,
                        express: chat.express === 1,
                        payment: chat.payment === 1,
                    })
                }

                setInitLoading(false);

            }
        });
    }, [setTimeoutDelay, setChatSettings])

    const showError = useCallback((text) => {
        setErrorText(text);
        setIsErrorShown(true);
    }, [])

    const hideError = useCallback((text) => {
        setErrorText('');
        setIsErrorShown(false);
    }, [])

    const setPhoto = useCallback((photoSrc) => {
        setPhotoSrc(photoSrc)
    }, [])

    const value = {
        errorText,
        isErrorShown,
        photoSrc,
        showError,
        hideError,
        setPhoto,
        paywallMethod,
        setPaywallMethod,
        timeoutDelay,
        setTimeoutDelay,
        allowedPhotoShow,
        allowedNotifications,
        initLoading,
        blockSettings,
        historyBlockSettings,
        selfieMode,
        showExpressScore,
        showSharing,
        showBlockReport,
        showButtonTips,
        showPotential,
        showNewDesign,
        reportExamples,
        showExamples,
        fullAnalysis,
        fullAnalysisButton,
        headerImgsShow,
        showChatOnUpload: !!chatSettings.upload,
        showChatOnExpress: !!chatSettings.express,
        showChatOnPayment: !!chatSettings.payment,
        chatModalOpened,
        openChatModal,
        closeChatModal,
        chatMode,
        step2Data,
        showButtonTipsUpload
    }


    return <>
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    </>
}


export const useShowError = () => useContextSelector(AppContext, value => value.showError)
export const useHideError = () => useContextSelector(AppContext, value => value.hideError)
export const useErrorText = () => useContextSelector(AppContext, value => value.errorText)
export const useErrorShown = () => useContextSelector(AppContext, value => value.isErrorShown)
export const useSetPhoto = () => useContextSelector(AppContext, value => value.setPhoto)
export const usePhoto = () => useContextSelector(AppContext, value => value.photoSrc)
export const useSetPaywallMethod = () => useContextSelector(AppContext, value => value.setPaywallMethod)
export const usePaywallMethod = () => useContextSelector(AppContext, value => value.paywallMethod)
export const useTimerDelay = () => useContextSelector(AppContext, value => value.timeoutDelay)
export const useSetTimeoutDelay = () => useContextSelector(AppContext, value => value.setTimeoutDelay)
export const useAllowedPhotoShow = () => useContextSelector(AppContext, value => value.allowedPhotoShow)
export const useAllowedNotifications = () => useContextSelector(AppContext, value => value.allowedNotifications)
export const useInitLoading = () => useContextSelector(AppContext, value => value.initLoading)
export const useBlockSettings = () => useContextSelector(AppContext, value => value.blockSettings)
export const useHistoryBlockSettings = () => useContextSelector(AppContext, value => value.historyBlockSettings)
export const useSelfieMode = () => useContextSelector(AppContext, value => value.selfieMode)
export const useShowExpressScore = () => useContextSelector(AppContext, value => value.showExpressScore)
export const useShowSharing = () => useContextSelector(AppContext, value => value.showSharing)
export const useShowBlockReport = () => useContextSelector(AppContext, value => value.showBlockReport)
export const useShowButtonTips = () => useContextSelector(AppContext, value => value.showButtonTips)
export const useShowPotential = () => useContextSelector(AppContext, value => value.showPotential)
export const useShowNewDesign = () => useContextSelector(AppContext, value => value.showNewDesign)
export const useReportExamples = () => useContextSelector(AppContext, value => value.reportExamples)
export const useShowExamples = () => useContextSelector(AppContext, value => value.showExamples)
export const useFullAnalysis = () => useContextSelector(AppContext, value => value.fullAnalysis)
export const useFullAnalysisButton = () => useContextSelector(AppContext, value => value.fullAnalysisButton)
export const useHeaderImgShow = () => useContextSelector(AppContext, value => value.headerImgsShow)
export const useShowChatOnUpload = () => useContextSelector(AppContext, value => value.showChatOnUpload)
export const useShowChatOnExpress = () => useContextSelector(AppContext, value => value.showChatOnExpress)
export const useShowChatOnPayment = () => useContextSelector(AppContext, value => value.showChatOnPayment)
export const useCloseChatModal = () => useContextSelector(AppContext, value => value.closeChatModal)
export const useOpenChatModal = () => useContextSelector(AppContext, value => value.openChatModal)
export const useChatModalOpened = () => useContextSelector(AppContext, value => value.chatModalOpened)
export const useChatMode = () => useContextSelector(AppContext, value => value.chatMode)
export const useStep2 = () => useContextSelector(AppContext, value => value.step2Data)
export const useShowButtonTipsUpload = () => useContextSelector(AppContext, value => value.showButtonTipsUpload)
