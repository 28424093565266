
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import usePhotoLoader from '../hooks/usePhotoLoader';

import { useHistory } from "react-router-dom";
import { Loader } from '../components/Loader';
import mainImageSrc from '../images/main-image.webp';
import scoreExampleSrc from '../images/result-score-speedometer-example.webp';

import ratingsExampleSrc from '../images/ratings-example.webp';
import iosSrc from '../images/app-store@3x.webp';
import personSrc from '../images/example-person.webp';
import personCircleSrc from '../images/person-circle.webp';
import androidSrc from '../images/google-play@3x.webp';
import previewGif from '../images/video-potential.gif';
import { Link } from 'react-router-dom';
import { useChatModalOpened, useCloseChatModal, useFullAnalysis, useFullAnalysisButton, useHeaderImgShow, useHistoryBlockSettings, useInitLoading, useOpenChatModal, useReportExamples, useSelfieMode, useSetPaywallMethod, useSetPhoto, useShowButtonTipsUpload, useShowChatOnUpload, useShowError, useShowExamples, useShowNewDesign, useStep2 } from '../context';
import { makeRequest } from '../utils/request';
import SimpleCarousel from '../components/Carousel';
import { Dots } from '../components/Carousel/Dots';
import { isAndroid, isTelegram, renderWithLineBreaks } from '../utils/index';

import { useTranslation } from 'react-i18next';
import { IconGallery } from '../icons';
// import { MainPhotoLoadingModal } from '../components/ModalMainPhotoLoading';
import { useCloseModal, useModalLoadingOpened, useOnStartLoadPhoto } from '../context/PhotoLoadingContext';
import imageChart1 from '../images/chart-s.webp';
import imageChart2 from '../images/chart-p.webp';
import { NewMainPage } from './NewMainPage';
import { ModalChat } from '../components/ModalChat';

const MainPhotoLoadingModal = React.lazy(() => import(/* webpackChunkName: "modal" */'../components/ModalMainPhotoLoading'));


export function MainPage() {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    const onStartLoadPhoto = useOnStartLoadPhoto();

    const { openFileSelector, photoBlob } = usePhotoLoader(onStartLoadPhoto)
    const [currentIndex, setCurrentIndex] = useState(0);
    const showError = useShowError();
    const setPhotoSrc = useSetPhoto()
    const showExamples = useShowExamples();

    const setPaywallMethod = useSetPaywallMethod();
    const initLoading = false;// useInitLoading();
    const loadingModalOpened = useModalLoadingOpened();
    const closeLoadingModal = useCloseModal();
    const reportExamples = useReportExamples();
    const showNewDesign = true;
    const showImages = useHeaderImgShow()

    const historyBlock = useHistoryBlockSettings();
    const fullAnalysis = useFullAnalysis();
    const fullAnalysisButton = useFullAnalysisButton();
    const selfieMode = useSelfieMode();

    const showChatButton = useShowChatOnUpload();

    const chatModalOpened = useChatModalOpened();
    const closeChatModal = useCloseChatModal();
    const openChatModal = useOpenChatModal();

    const showButtonTips = useShowButtonTipsUpload();

    const step2Data = useStep2();
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff');
        document.querySelector('body').style = 'background-color: #ffffff'
        setPaywallMethod('none');
    }, [setPaywallMethod])


    const onClickHistoryBlock = useCallback(() => {
        if (historyBlock.photoCount === 1) {
            history.push(`/${historyBlock.photoId}`);
        } else {
            history.push(`/history`);
        }
    }, [history, historyBlock])

    const onClickFeedBack = useCallback(() => {
        history.push('/feedback');
    }, [history])

    const onClickAiChat = useCallback(() => {
        // alert(`This method isn't implemented yet`);
        openChatModal('upload');
    }, [openChatModal])

    useEffect(() => {
        if (isTelegram()) {
            const routeId = window.__tgAppRedirectId;
            if (routeId) {
                window.__tgAppRedirectId = '';
                history.push(`/${routeId}`);
            }
        }
    });

    const openWebcam = useCallback(() => {
        window.dataLayer.push({ 'event': 'take_a_selfie' });
        openFileSelector('webcam')
    }, [openFileSelector])

    const openGalleryForPhotos = useCallback(() => {
        window.dataLayer.push({ 'event': 'upload_photo' });
        openFileSelector('none')
    }, [openFileSelector])

    return (
        <>{(isLoading || initLoading) ? <Loader /> :
            <>
                {
                    showNewDesign ?
                        <NewMainPage
                            showExamples={showExamples}
                            reportExamples={reportExamples}
                            onClickLoadingElem={openGalleryForPhotos}
                            historyBlock={historyBlock}
                            onClickHistoryBlock={onClickHistoryBlock}
                            onClickFeedBack={onClickFeedBack}
                            fullAnalysis={fullAnalysis}
                            fullAnalysisButton={fullAnalysisButton}
                            showImages={showImages}
                            selfieMode={selfieMode}
                            onClickAiChat={onClickAiChat}
                            showChatButton={showChatButton}
                            step2Data={step2Data}
                            showButtonTips={showButtonTips}
                        /> :

                        <div className='flex flex-col items-center relative justify-center pt-[38px] pb-[40px] px-[20px]'>
                            <>
                                {historyBlock.photoCount > 0 && <div onClick={onClickHistoryBlock} className='min-w-[144px] h-[60px] flex rounded-[32px] pr-[16px] mb-[34px] bg-[#f6f7f8] cursor-pointer'>
                                    <img src={historyBlock.photoSrc} alt="" className='h-[60px] w-[60px] rounded-[32px] ' />
                                    <div className='ml-[10px] text-[#242b46] self-center text-[18px] leading-[24px]'>
                                        {historyBlock.caption}
                                    </div>
                                </div>}
                                <div className='relative z-3 rounded-[48px] bg-[#811883] h-[464px] max-w-[335px] w-[335px] flex flex-col justify-center items-center'>
                                    <img src={mainImageSrc} alt="" className='w-[220px]' />
                                    <SimpleCarousel containerClassName="h-[130px]" onChangeCurrentIndex={setCurrentIndex} >
                                        <div className={`leading-[1.2] text-center font-bold text-[32px] text-white  mt-[18px] font-['Montserrat'] select-none`}>
                                            {renderWithLineBreaks(t('UPLOAD_MESSAGE_1'))}
                                        </div>
                                        <div className={`leading-[1.2] text-center font-bold text-[32px] text-white  mt-[18px] font-['Montserrat'] select-none`}>
                                            {renderWithLineBreaks(t('UPLOAD_MESSAGE_2'))}
                                        </div>
                                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white  mt-[18px] font-['Montserrat'] select-none`}>
                                            {renderWithLineBreaks(t('UPLOAD_MESSAGE_3'))}
                                        </div>

                                    </SimpleCarousel>

                                    <div className='absolute z-2 rounded-t-[32px] w-[315px] left-[10px] top-[-12px] h-[60px] bg-[rgba(129,24,131,0.5)]'></div>
                                    <div className='absolute z-1 rounded-t-[32px] w-[300px] left-[18px] top-[-24px] h-[60px] bg-[rgba(129,24,131,0.3)]'></div>
                                    <div className='absolute z-4 bottom-[20px] w-full flex justify-center'>
                                        <div className='w-[36px] flex justify-between items-center '>
                                            <Dots amount={3} selectedIndex={currentIndex} />
                                        </div>
                                    </div>

                                </div>

                                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full flex flex-col py-[20px]  items-center'>
                                    <div className='leading-[1.33] text-center font-bold font-header flex self-center  text-[#232948] text-[18px]'>
                                        {renderWithLineBreaks(t('UPLOAD_SELFIE_1_TITLE'))}
                                    </div>
                                    <div style={{ borderRadius: '46px', }} className='mt-[23px] h-[325px] w-[241px] relative overflow-hidden rounded-[46px]'>
                                        <img src={personSrc} alt="" className=' h-[325px] w-[241px]' />
                                    </div>

                                    <div className='mt-[20px] flex'>
                                        {(isAndroid() && isTelegram()) ?
                                            <button onClick={openGalleryForPhotos} className='flex  font-bold  w-[241px] h-[60px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                                                {renderWithLineBreaks(t('UPLOAD_SELFIE_1_BUTTON_IMG'))}
                                            </button>
                                            :
                                            <>
                                                <button onClick={openGalleryForPhotos} className='md:hidden font-bold  w-[64px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[50%]'>
                                                    <IconGallery />
                                                </button>

                                                <button onClick={openWebcam} className='md:hidden font-bold ml-[10px]  w-[161px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                                                    {renderWithLineBreaks(t('UPLOAD_SELFIE_1_BUTTON_CAMERA'))}
                                                </button>

                                                <button onClick={openGalleryForPhotos} className='md:flex hidden font-bold  w-[241px] h-[60px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                                                    {renderWithLineBreaks(t('UPLOAD_SELFIE_1_BUTTON_IMG'))}
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>


                                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full items-center flex flex-col py-[22px] pb-[20px]'>
                                    <div className='leading-[1.33] font-header text-center tracking-[0.5] font-bold flex self-center text-[#232948] text-[18px]'>
                                        {renderWithLineBreaks(t('UPLOAD_SCORE_TITLE'))}
                                    </div>
                                    <div className='mt-[18px] rounded-[48px] bg-[#242b46] h-[278px] w-[240px] pt-[18px] flex flex-col items-center'>
                                        <img alt="" src={personCircleSrc} className='w-[107px] h-[107px] rounded-[50%]' />
                                        <img src={scoreExampleSrc} alt="" className='h-[80px] w-[147px] mt-[18px]' />
                                        <div className="font-['Montserrat'] text-[20px] font-bold text-center mt-[6px] tracking-[0.87px] text-white">
                                            {renderWithLineBreaks(t('UPLOAD_SCORE_STATUS'))}
                                        </div>
                                    </div>
                                </div>


                                <div className='bg-[#f6f7f8] mt-[18px] rounded-[48px] max-w-[335px] w-full items-center flex flex-col pt-[22px] pb-[23px]'>
                                    <div className=' leading-[1.33] font-header text-center font-bold flex self-center text-[#232948] text-[18px]'>
                                        {renderWithLineBreaks(t('UPLOAD_ANALYSIS_TITLE'))}
                                    </div>

                                    <div className='self-center w-[241px] mt-[18px] flex gap-[8px]  flex-wrap'>
                                        <div className='flex flex-col w-[116px] h-[116px] bg-[#242a46] rounded-[16px] items-center justify-center'>
                                            <div className='text-[13px] text-center text-[#f2f2f2] leading-[12px] font-[600] '>
                                                Symmetry
                                            </div>
                                            <div className='flex items-center self-center justify-center w-[67px] h-[67px] mt-[7px] relative'>
                                                <img src={imageChart1} alt="" className='w-[67px] h-[67px] absolute top-0 left-0' />
                                                <div className='text-[24px] font-bold text-[#f2f2f2] self-center leading-[67px]'>
                                                    70
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-col w-[116px] h-[116px] bg-[#242a46] rounded-[16px] items-center justify-center'>
                                            <div className='text-[13px] text-center text-[#f2f2f2] leading-[12px] font-[600] '>
                                                Proportions
                                            </div>
                                            <div className='flex items-center self-center justify-center w-[67px] h-[67px] mt-[7px] relative'>
                                                <img src={imageChart2} alt="" className='w-[67px] h-[67px] absolute top-0 left-0' />
                                                <div className='text-[24px] font-bold text-[#f2f2f2] self-center leading-[67px]'>
                                                    66
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='flex  w-[241px] rounded-[16px] bg-[#242a46] h-[53px] justify-between items-center px-[25px] mt-[8px] text-[#f2f2f2]'>
                                        <div className='text-[13px] font-[600]'>
                                            Face Shape
                                        </div>

                                        <div className='text-[13px] font-[900] uppercase'>
                                            OVAL
                                        </div>
                                    </div>

                                    <div className='flex  w-[241px] rounded-[16px] bg-[#242a46] h-[53px] justify-between items-center px-[25px] mt-[8px] text-[#f2f2f2]'>
                                        <div className='text-[13px] font-[600]'>
                                            Canthal Tif
                                        </div>

                                        <div className='text-[13px] font-[900] uppercase'>
                                            Neutral
                                        </div>
                                    </div>

                                    {/* <img src={ratingsExampleSrc} alt="" className='mt-[18px] h-[278px] w-[241px]' /> */}
                                </div>

                                {/* <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full items-center flex flex-col pt-[22px] pb-[20px]'>
                    <div className=' leading-[1.33] font-header text-center font-bold flex self-center text-[#232948] text-[18px]'>
                        {renderWithLineBreaks(t('UPLOAD_FEATURES_TITLE'))}
                    </div>

                    <div className='w-[241px] h-[253px] mt-[6px] relative flex justify-center items-center'>
                        <img src={diamondSrc} alt="" className='w-[241px] h-[253px] absolute top-0 left-0' />
                        <img src={personCircleSrc} className='w-[80px] h-[80px] rounded-[50%] mt-[13.5px] ml-[0.5px]' alt="" />
                    </div>
                </div> */}

                                <div className='bg-[#f6f7f8] mt-[20px] rounded-[48px] max-w-[335px] w-full items-center flex flex-col py-[20px]'>
                                    <div className=' leading-[1.33] font-header text-center font-bold flex self-center text-[#232948] text-[18px]'>
                                        {renderWithLineBreaks(t('UPLOAD_IMAGE_TITLE'))}
                                    </div>
                                    <img src={previewGif} alt="" className='mt-[18px] h-[278px] w-[241px] rounded-[48px]' />
                                </div>

                                {/* <div className='mt-[30px] flex justify-center gap-[9px] self-center cursor-pointer'>
                    <span onClick={clickAppStore}>
                        <img src={iosSrc} className='w-[120px] h-[41px]' alt="" />
                    </span>

                    <span onClick={clickGooglePlay}>
                        <img src={androidSrc} className='w-[120px] h-[41px]' alt="" />
                    </span>
                </div> */}

                                {/* <div className='max-w-[335px] w-full flex justify-center gap-[10px] mt-[23px] border-solid border-t border-[#dde0eb] pt-[23px]'>
                    <Link to="/pri" className="font-header text-[13px] text-[#a4a6b2] text-right w-[100px]">{renderWithLineBreaks(t('UPLOAD_PRIVACY'))}</Link>
                    <div className=' w-[0px] h-[20px] self-center border-solid border-l border-[#dde0eb]' />
                    <Link to="/ter" className=" self-center text-[#a4a6b2] w-[100px] text-left font-header text-[13px] ">{renderWithLineBreaks(t('UPLOAD_TERMS'))}</Link>
                </div> */}
                                <div className='max-w-[335px] w-full text-center text-[12px] text-[#a4a6b2] mt-[40px]'>
                                    {renderWithLineBreaks(t('COPYRIGHT'))}
                                </div>
                                {/* <PhotoLoadingModal opened={modalOpened} onClose={closeModal} /> */}


                            </>

                        </div>
                }
                <Suspense fallback={<></>}>
                    <MainPhotoLoadingModal opened={loadingModalOpened} onClose={closeLoadingModal} />
                </Suspense>
                <ModalChat opened={chatModalOpened} close={closeChatModal} />
            </>
        }
        </>
    );
}